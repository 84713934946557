import React from "react"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"

import Seo from "../components/Seo"
import { Layout } from "../components/Layout"

const NotFoundPage: React.FC = () => {
  const pageName: string = "404エラー";

  return (
    <Layout pageName={pageName}>
      <Seo title="404: Not found" />
      <Container className="pb-4">
        <h2 className="subtitle text-center">
          お探しのページが
          <br className="d-md-none" />
          見つかりませんでした
        </h2>
        <p className="contact-thanks-message text-center">
          URLをお確かめの上、
          <br className="d-md-none" />
          もう一度お試しください。
        </p>
        <Link to="/" className="btn-contact btn-effect my-5">
          トップページへ
        </Link>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
